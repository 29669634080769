/* eslint-disable */
import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnIcon,
  TrophyFillIcon,
  MoreIcon,
  TrophyIcon,
  NftFillIcon
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, chainId) =>
    [
      {
        label: t('Swap'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: t('Pools'),
        icon: TrophyIcon,
        fillIcon: TrophyFillIcon,
        href: '/liquidity',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: 'Launchpad',
        href: '#',
        icon: NftFillIcon,
        type: DropdownMenuItemType.EXTERNAL_LINK,
        items: [
          {
            label: "Bridge",
            href: "#sec",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Nfts",
            href: "https://lucclub.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Game",
            href: "#game",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Staking",
            href: "https://dannycoin.io/stake/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Lottery",
            href: "#Lottery",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Marketplace",
            href: "https://shop.line.me/@663mjfyd",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ]
      },
      {
        label: "Earn",
        href: "#",
        icon: EarnIcon,
        items: [
          {
            label: "Academy",
            href: "https://dannygroup.gitbook.io/web3academy",
            type: DropdownMenuItemType.EXTERNAL_LINK
          },
          {
            label: "Document",
            href: "https://danny-academy.gitbook.io/academyweb3",
            type: DropdownMenuItemType.EXTERNAL_LINK
          },
          {
            label: "Forex",
            href: "https://wt-trade.net/",
            type: DropdownMenuItemType.EXTERNAL_LINK
          },
          {
            label: "Lotto",
            href: "https://duangdeelotto.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK
          },
          {
            label: "Chart",
            href: "https://www.dextools.io/app/en/bnb/pair-explorer/0x43c92688ab7b5633fd165010feff4e3852162a5f",
            type: DropdownMenuItemType.EXTERNAL_LINK
          }
        ]
      }
    ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config